.contentApp {
  overflow-x: hidden;
  overflow-y: auto;
}
 
.contentApp::-webkit-scrollbar {
  -webkit-appearance: none;
}

.contentApp::-webkit-scrollbar:vertical {
  width:10px;
}

.contentApp::-webkit-scrollbar-button:increment,.contentApp::-webkit-scrollbar-button {
  display: none;
} 

.contentApp::-webkit-scrollbar:horizontal {
  height: 10px;
}

.contentApp::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.contentApp::-webkit-scrollbar-track {
  border-radius: 10px;  
}


.App {
  /* text-align: center; */
  background-image: url('./img/05137d43523ff0b81151b7f0e9eae6ae-bg_7.jpg');
  height: 100vh; 
  background-size: cover; 
  background-repeat: no-repeat;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
 

.App-header {
  /* background-color: #fff; */
  /* min-height: 100px; */
  height:70px;

  /* width: 120px; */ 
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;

  .logo {
    width: 100px;
  }

}

.cursor-pointer {
  cursor: pointer;
}

.h-100 {
  height: 100%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
