.content {
    padding: 15px;
    padding-bottom: 40px;
}

.articulosContent {
    height: calc(100vh - 260px);
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
}


.border-select {
    border: #0d6efd solid 2px;
}
  
  
/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/

.articulosContent::-webkit-scrollbar {
    -webkit-appearance: none;
}

.articulosContent::-webkit-scrollbar:vertical {
    width:10px;
}

.articulosContent::-webkit-scrollbar-button:increment,.articulosContent::-webkit-scrollbar-button {
    display: none;
} 

.articulosContent::-webkit-scrollbar:horizontal {
    height: 10px;
}

.articulosContent::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.articulosContent::-webkit-scrollbar-track {
    border-radius: 10px;  
}


.imgCard {
    object-fit: contain;
    object-position: center center;
    height: 180px;

}

.imgCardPedido {
    object-fit: contain;
    object-position: center center;
    height: 40px;
}


.fs-7 {
    font-size: 13px;
}

.customButtom {
    border-top-left-radius: 0px !important; 
    border-top-right-radius: 0px  !important; 
} 


.contentPedido {
    height: calc(100vh - 183px);
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
}

.scrollPedidos {
      
    overflow-x: hidden;
    overflow-y: auto; 
}

.buttomRight {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 5px !important;

}

.noPrice {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00000080;
    color: #fff;
    border-radius: 5px;
}
