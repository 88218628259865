.footer {
    position: relative;
    -webkit-mask-image: url(../../img/svg/shape_three.svg); 
    -webkit-mask-position: top;
    -webkit-mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.3); 
    color: #ffffff;
    padding: 30px 0;
    padding-top: 50px;
    margin-top: -20px;
    text-align: center; 
}

.contentFooter {
    background-color: #2c779a;
    
}